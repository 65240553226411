/* first level ul style */
.cbp-hrmenu {
    .content-nav > ul,
    .cbp-hrsub-inner {
        margin: 0 auto;
        line-height: initial !important;

        > li {
            @include media-breakpoint-up(xl) {
                display: inline-block !important;
            }

            > a,
            > span.cbp-main-link {
                display: inline-block;
                //position: relative;
                text-decoration: none;
                cursor: pointer;
                transition: color 0.3s ease, background-color 0.3s ease;
                padding: 20px;
                text-transform: uppercase;
                transition: all ease .3s !important;

                &:hover {
                    opacity: 0.6;
                    background: theme-color(light);
                }
            }

            &:not(.cbp-pulled-right) > a {
                font-weight: 700;
            }

            &:first-child > a,
            &:first-child > span.cbp-main-link {
                border-left: none !important;
            }
        }
    }
}