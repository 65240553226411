.themes-- {
    &main-cover {
        img {
            background: theme-color(gray);
            width: 100%;
            height: auto;
        }
        .card {
            margin: -15% auto 0 auto;
            border: none;
            max-width: 90%;

            @include media-breakpoint-up(md) {
                max-width: 60%;

                .card-body {
                    padding: 40px;
                }
            }

            h1 {
                &::before {
                    content: attr(data-before);
                    text-transform: uppercase;
                    font-size: 0.9rem;
                    display: block;
                    font-weight: 500;
                }
            }
            .title-category {
                font-size: 2.2rem;
            }

            .themes--description {
                @extend .lead;
            }
        }
    }
    &categories, &rooms {
        .item {
            margin-bottom: 30px;

            a {
                .img {
                    display: block;
                    position: relative;
                    
                    &::before {
                        content: "";
                        display: block;
                        padding-top: 100%;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                        background: theme-color(gray);
                        display: block;
                        @extend %transition;
                    }
                }
                .title {
                    display: block;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: right;
                }
                &:hover img {
                    @extend %shadow;
                }
            }
        }
    }
    &main-argument {
        .content {
            font-size: 1.1rem;

            @include media-breakpoint-up(md) {
                padding: 30px 90px;
            }

            a:not(.btn) {
                @extend %link-underline;
            }
        }
    }
    &main-products {
        .owl-carousel .owl-stage-outer {
            overflow: visible;
        }
    }
    &advice {
        .content {
            padding-left: 45px;
            padding-right: 45px;
            position: relative;
            font-size: 1.1rem;

                
            a:not(.btn) {
                @extend %link-underline;
            }

            i.las {
                font-size: 2rem;
                position: absolute;
                z-index: 2;

                &.la-quote-left {
                    top: 0;
                    left: 0;
                }
                &.la-quote-right {
                    bottom: 0;
                    right: 0;
                }
            }
        }
        .col-img img {
            @include media-breakpoint-up(sm) {
                max-width: 110%;
            }
            @extend %shadow;
        }
    }

    &second-argument {
        .col-left {
            @include media-breakpoint-up(md) {
                padding-bottom: 120px;
            }
        }
        .content {
            font-size: 1.1rem;

            @include media-breakpoint-up(md) {
                padding: 0 60px;
            }
        }
    }
    &second-cover {
        img {
            background: theme-color(gray);
            width: 100%;
            height: 40vh;
            object-fit: cover;
            object-position: center center;
        }       
    }
    &other-themes {
        .item {
            margin-bottom: 30px;

            a {
                .img {
                    display: block;
                    position: relative;
                    
                    &::before {
                        content: "";
                        display: block;
                        padding-top: 125%;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                        background: theme-color(gray);
                        display: block;
                        @extend %transition;
                    }
                }
                .title {
                    display: block;
                    font-size: 1.1rem;
                    font-weight: 600;
                    text-align: right;
                }
                &:hover img {
                    @extend %shadow;
                }
            }
        }
    }
}