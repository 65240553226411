header#header {
    background: theme-color(white);
    //box-shadow: 0 3px 0 rgba(theme-color(primary), 0.2);
    border-bottom: rgba(theme-color(primary), 0.2) solid 3px;

    .customer-tool {
        a {
            position: relative;
            display: inline-block;
            line-height: 0.8;
            @extend %transition;

            &:hover {
                opacity: 0.6;
            }
     
            i {
                display: block;
                font-size: 1.8rem;
                line-height: 1;
                margin-bottom: 8px;
            }
            span {
                font-size: 0.9rem;
                font-weight: 500;
            }
        }
        .cart-products-count {
            position: absolute;
            top: -2px;
            right: -5px;
            z-index: 1;
            display: block;
            font-size: 0.7rem;
            border-radius: 10px;
            padding: 5px;
            text-align: center;
            border: theme-color(white) solid 2px;
            font-weight: 600;
            color: theme-color(white);
            background: theme-color(quaternary);
        }
    }

    @include media-breakpoint-down(lg) {
        .col-auto {
            padding-left: 6px;
            padding-right: 6px;
        }
        .customer-tool i, .search-mobile {
            margin-bottom: 0 !important;
            font-size: 1.5rem !important;
            cursor: pointer;
        }
    }
}