.block_newsletter {
   .title {
       font-size: 1.4rem;
       line-height: 1.15;
       margin-bottom: 20px;

        &::after {
            content: "";
            display: block;
            height: 1px;
            width: 120px;
            background: theme-color(primary);
            margin-top: 20px;
        }

       span {
           color: theme-color(quaternary);
           font-weight: 700;
       }
   }
   p {
       font-size: 1rem;
   }
}