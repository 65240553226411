body {
    background: theme-color(light);

    &.overlay {
        background: theme-color(primary);

        main, #footer {
            @extend %transition;
            opacity: 0.4;
        }
    }
}

body, html {
    height: 100%;

    &.fixed-body {
        @include media-breakpoint-down(lg) {
            overflow: hidden;
            width: 100vw;
            height: 100vh;
            overscroll-behavior: contain;
        }
    }
}

.hide {
    @extend .d-none;
}
.sticky-top {
    z-index: 1;
}

* {
    outline: none !important;
}

::selection{
    background-color: theme-color(secondary);
    color: theme-color(primary);
}

a:hover {
    text-decoration: none;
}

main {
    background: theme-color(light);
}

.lead {
    font-size: 1.10rem;
}

section {
    padding-top: 50px;
    padding-bottom: 50px;

    @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
        &.full-prev::before, .full-prev::before {
            display: none !important;
        }
    }
}

.shadow-box {
    box-shadow: rgba(theme-color(gray), 0.3) 0px 3px 6px 0px;
}

.triangle {
    position: relative;

    @include media-breakpoint-down(sm) {
        padding-top: 50px !important;
    }

    &::after {
        content: "";
        display: block;
        width: 103px;
        height: 88px;
        background: url("../img/triangle.svg");
        position: absolute;
        top: -20px;
        right: -20px;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            width: 50px;
            height: 43px;
            background: url("../img/triangle.svg");
            position: absolute;
            top: -12px;
            right: 15px;
            z-index: 1;
        }
    }
}

@include media-breakpoint-down(sm) {
    .section--full-prev {
        .container {
            padding: 0;

            .full-prev {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.icon-color-france {
    background: theme-color(white);
    width: 1.2rem;
    height: 0.9rem;
    padding: 0 0.4rem;
    display: inline-block;
    position: relative;

    @include media-breakpoint-down(md) {
        width: 1.8rem;
        height: 1.5rem;
    }

    &::before, &::after {
        display: inline-block;
        height: 0.9rem;
        width: 0.4rem;
        position: absolute;
        top: 0;
        z-index: 1;

        @include media-breakpoint-down(md) {
            width: 0.6rem;
            height: 1.5rem;
        }
    }
    &::before {
        content: "";
        background: theme-color(blue_fr);
        left: 0;
    }
    &::after {
        content: "";
        background: theme-color(red_fr);
        right: 0;
    }
}

@include media-breakpoint-up(sm) {
    .w-100.w-md-auto {
        width: auto !important;
    }
}

hr {
    border-top-color: rgba(theme-color(primary), 0.6) !important;
}

strong, b {
    font-weight: 500;
}

.cursor-pointer {
    cursor: pointer;
}

@include media-breakpoint-down(lg) {
    .container {
        max-width: 100%;
    }
}

.modal-body {
    img {
        @extend .img-fluid;
    }
}