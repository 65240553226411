#search_filters {
    background: theme-color(white);
    @extend %shadow;

    .title {
        cursor: pointer;
        padding: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: block;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    @include media-breakpoint-up(md) {
        .unvisible-facet {
            display: none;
        }
    }
    .btn-all-filters {
        font-weight: 500;
        text-transform: uppercase;
        border: none;
        background: none;
        padding-left: 0;
        padding-right: 0;

        &:not(.active) {
            .less {
                display: none;
            }
        }
        &.active {
            //margin-top: 15px;

            .more {
                display: none;
            }
        }
    }

    .facet {
        .facet-title {
            display: inline-block;
            width: auto;
        }
        .facet-title + i {
            transition: all ease .3s;
            transform-origin: center center;
            transform: translateY(-5px);
            margin-left: 8px;
        }
        ul {
            overflow: hidden;
            height: 0;
            transition: all ease .3s;
        }
        &.open {
            .title {
                background: lighten(theme-color(tertiary), 5%);
            }

            .facet-title + i {
                transform: rotate(180deg);
            }
            ul {
                overflow: visible;
                height: auto;
                padding-top: 10px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        [class*="col-"] {
            padding: 20px;
        }
        .total-product {
            text-align: right;
            font-weight: 600;
            color: theme-color(quaternary);
        }
        .facet {
            position: relative;

            ul {
                transition: none;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 5;
                //transform: translateX(-50%);
                min-width: 100%;
                background: lighten(theme-color(tertiary), 5%);
            }

            &.open ul {
                padding: 15px;
                border-top: rgba(theme-color(primary), 0.3) solid 2px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        #panel-facets {
            position: fixed;
            top: 0;
            left: -100vw;
            z-index: 1000;
            width: 100vw;
            height: 100vh;
            padding: 0;
            padding-top: 90px;
            background: theme-color(light);
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
            display: flex;
            flex-wrap: wrap;

            & + .col-6 {
                padding: 0;

                .open-facets-panel-mobile {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-size: 1rem;
                }
            }

            &.open {
                left: 0;
            }

            .content-facet {
                display: block;
                padding: 30px;
                height: 65vh;
                width: 100%;
                overflow-y: auto;
            }
            .footer-facets-mobile {
                align-self: end;
                padding: 30px;
                width: 100%;
                background: theme-color(white);
            }

            .close-facets-mobile {
                position: absolute;
                top: 30px;
                right: 30px;
                z-index: 1;
                text-transform: uppercase;
                text-align: right;
                cursor: pointer;
            }

            .facet {
                padding: 15px 0 !important;
                border-bottom: theme-color(primary) solid 1px;
            
                .facet-title {
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }
}