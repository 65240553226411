#home-rooms {
    a {
        text-align: right;
        display: block;

        &:hover img {
            @extend %shadow;
        }
        
        img {
            display: block;
            width: 100%;
            height: 250px;
            background: theme-color(white);
            object-fit: cover;
            object-position: top center;
            @extend %transition;
        }
        span {
            font-size: 1.1rem;
            font-weight: 500;
            display: block;
            margin-top: 10px;
            line-height: 1.1;
        }
    }
}