#main-menu, .container-iqit-menu {
    background: theme-color(white);

    .navbar-collapse {
        border-top: rgba(theme-color(primary), 0.2) solid 2px;
    }
}

//button open mobile navigation
.navbar-toggler {
    border: none;
    border-radius: 0;
    padding: 10px;
    height: 40px;
    cursor: pointer;
    
    span {
        background: theme-color(primary);
        width: 25px;
        height: 2px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        transition: all ease .3s;
        transform-origin: center center;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
    &[aria-expanded=true] {
        span:nth-child(1) {
            transform: rotate(-45deg) translateY(2px);
            margin-bottom: 0;
        }
        span:nth-child(2) {
            display: none;
        }
        span:nth-child(3) {
            transform: rotate(45deg) translateY(-1px);
            margin-bottom: 0;
        }
    }
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;

    .inner-nav {
        width: 100%;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }
    .column_wrap_title {
        display: block;
        line-height: 1.1;
        margin-bottom: 15px;
    }
    .dropdown-menu ul li {
        line-height: 1.1;
        margin-bottom: 10px;
    }

    @include media-breakpoint-between(md, lg) {
        > .container {
            max-width: 100%;
            padding: 0 !important;
        }
    }
    
    @include media-breakpoint-up(lg) {
        .nav-item {
            transition: all ease .25s;

            &:hover {
                .sub-menu {
                    display: block;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    height: auto;
                    overflow: visible;
                }
            }

            .a-niveau1 {
                position: relative;
            }
        }
        .nav-item .advtm_menu_actif {
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 4px;
                display: block;
                background: theme-color(secondary);
                position: absolute;
                bottom: -1px;
                left: 0;
                z-index: 1;
            }
            & {
                color: theme-color(secondary);
            }
        }
        .nav-item.show:not(.active) > a, .nav-item:not(.active):hover > a {
            color: theme-color(secondary);
            background: lighten(theme-color(light), 4%);
        }
    }
    .nav-item > a {
        font-size: 1rem !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        padding: 23px 15px !important;
        display: flex !important;
        height: 100%;
        vertical-align: middle;
        text-align: center;
        @extend %transition;
        
        @include media-breakpoint-up(lg) {
            line-height: normal !important;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            align-self: center;
            line-height: 1.1;
        }

        &:hover {
            text-decoration: none;
        }
    }
    .dropdown-toggle::after {
        display: none;
    }

    .column_wrap_title {
        font-weight: 700;
        font-size: 1.1rem;
    }

    @include media-breakpoint-up(lg) {
        .row {
            margin-left: -15px;
            margin-right: -15px;

            [class^="col-"], [class*=" col-"] {
                padding-left: 15px;
                padding-right: 15px;

                &:not(:last-child) { 
                    border-right: lighten(theme-color(gray), 18%) solid 1px;
                }
            }
        }
    }

    .has-children {
        position: static;

        .sub-menu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            height: 0;
            overflow: hidden;
            padding: 0;
            display: block !important;
            background: theme-color(light);
            border: none;
            border-radius: 0;
            width: 100%;
            margin: 0;

            a:not(.btn) {
                @extend %transition;

                &:hover {
                    color: theme-color(secondary);
                }
            }

            a:not(.btn) {
                color: theme-color(primary);
            }

            .title-col {
                text-transform: uppercase;
                font-size: 1.4rem;
                font-weight: 600;
                margin-bottom: 15px;
                color: theme-color(primary);
            }

            ul {
                padding-left: 0;
                list-style-type: none;
            }
        }
    }
}

header {
    .navbar {

        @include media-breakpoint-down(md) {
            .link-all {
                display: none;
            }

            .navbar-collapse {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                width: 100%;
                height: 100vh !important;
                background: rgba(theme-color(primary),0.6);
                opacity: 0;
                

                &.show {
                    left: 0;
                    opacity: 1;

                    .inner-nav {
                        margin-left: 0;
                    }
                }

                .inner-nav {
                    background: theme-color(white);
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                    overscroll-behavior: contain;
                    padding: 0;
                    top: 0;
                    left: 0;
                    margin-left: -100%;
                    z-index: 11;
                    width: 90vw !important;
                    height: 100%;
                    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;

                    .content-nav {
                        padding-top: 0;
                        padding-bottom: 200px;

                        .navbar-nav {
                            display: block;
                        }
                    }
                }

                ul {
                    padding-left: 0;
                    list-style-type: none;

                    & > .nav-item:not(:last-child) {
                        border-bottom: lighten(theme-color(gray), 20%) solid 1px;
                    }
                }

                .a-niveau1 {
                    font-size: 1.25rem !important;
                    color: theme-color(primary);
                    padding-left: 0;
                    text-align: left;
                }

                .dropdown-menu {
                    display: block;
                    background: theme-color(light);
                    position: absolute;
                    top: 0;
                    left: -100%;
                    height: 100vh;
                    overflow-y: scroll;
                    width: 90vw !important;
                    -webkit-overflow-scrolling: touch;
                    overscroll-behavior: contain;
                    z-index: 2;
                    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
                    margin-top: 0;

                    &.show {
                        left: 0;
                    }

                    .column_wrap_title {
                        font-size: 1.25rem;
                    }
                    ul a {
                        font-size: 1.25rem;
                    }

                    .container {
                        padding-bottom: 100px;
                    }

                    .btn-return {
                        font-size: 1.6rem;
                        margin-bottom: 30px;
                        color: lighten(theme-color(primary), 60%);
                        font-weight: 900;
                        cursor: pointer;
                        line-height: 1;

                        i {
                            font-size: 1rem;
                            margin-right: 10px;
                        }
                    }

                    div[class^="col"] {
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .dropdown {
            position: static;

            .a-niveau1 {
                display: block;
                position: relative;
                @extend .las;
                @extend .la-angle-down;

                &::before {
                    position: absolute;
                    top: 25px;
                    right: 15px;
                    z-index: 1;

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }

                span {
                    font-family: $font-family-base;
                }
            }

            .dropdown-menu {
                background: theme-color(light);
                padding-top: 30px;
                padding-bottom: 30px;
                border: none;
                border-radius: 0;
                width: 100%;

                img {
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(lg) {
                    transform: translateY(-2px);
                }

                a {
                    color: theme-color(primary);
                }

                .title-col {
                    text-transform: uppercase;
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                .link-all {
                    font-size: 13px;
                }

                ul {
                    padding-left: 0;
                    list-style-type: none;
                }
            }
        }
    }
}