@include media-breakpoint-down(lg) {
    .iqitmegamenu-wrapper {
        position: fixed;
        background: rgba(theme-color(primary), 0.6);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: -100%;
        z-index: 200;
        opacity: 0;

        &.open {
            transition: opacity ease 0.3s;
            opacity: 1;
            left: 0;

            .iqitmegamenu {
                transform: translateX(0);
                background: transparent;
            }
        }

        .inner-menu {
            height: 100%;
            overflow-y: auto;

            & > .row {
                padding-bottom: 200px;
            }
        }

        .cbp-column-title.cbp-category-title {
            font-size: 0.9rem !important;
        }

        .inner-nav {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            overscroll-behavior: contain;
            height: 100%;

            .content-nav {
                padding-top: 0;
                padding-bottom: 200px;
            }
        }

        .iqitmegamenu {
            transform: translateX(-100%);
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
        }

        .cbp-hrmenu {
            background: theme-color(white) !important;
            width: 90vw;
            height: 100vh;
            border: none !important;
            position: relative;

            .btn-nav-close {
                background: none;
                border: none;
                border-bottom: theme-color(primary) solid 1px;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1.05rem;
                padding: 10px;
            }
            .btn-nav-account {
                background: lighten(theme-color(tertiary), 10%);
                border-bottom: theme-color(primary) solid 1px;
                text-align: center;
                display: block;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1.05rem;
                padding: 10px;

                i {
                    font-size: 1.4rem;
                }
            }
            .return-tab-nav {
                background: lighten(theme-color(tertiary), 10%);
                text-transform: uppercase;
                border: none;
                border-bottom: theme-color(primary) solid 1px;
                font-weight: 500;
                width: calc(100% + 30px);
                font-weight: 500;
                font-size: 1.05rem;
                padding: 10px;
                text-align: left;
                margin-left: -15px;
                margin-right: -15px;
            }

            li, li a {
                display: block !important;
            }
            .pull-right,
            .cbp-pulled-right {
                float: none !important;
                text-align: center;

                &:hover a {
                    background: none !important;
                }
            }
            .cbp-hrmenu-tab:not(.cbp-pulled-right) {
                border-bottom: rgba(theme-color(primary), 0.2) solid 1px;

                a .cbp-tab-title {
                    position: relative;
                    display: block;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    font-size: 1.05rem;

                    &::after {
                        float: right;
                        display: block;
                        content: "";
                        background: url("../img/diviser.svg") no-repeat;
                        background-size: 15px 15px;
                        width: 15px;
                        height: 15px;
                        transition: all ease 0.3s;
                        margin-top: -6px;
                    }
                }
                &.show {
                    .cbp-hrsub {
                        overflow: visible;
                        visibility: visible !important;
                        height: auto !important;
                        opacity: 1 !important;
                        display: block;
                        float: none !important;
                    }
                    a .cbp-tab-title::after {
                        transform: rotate(90deg);
                    }
                }
            }
            .cbp-pulled-right {
                margin: 15px;
                font-weight: 500;
            }
            .cbp-hrmenu-tab.cbp-pulled-right a {
                padding: 10px;
            }
            .cbp-hrsub {
                overflow: hidden;
                height: 0 !important;
                position: static !important;

                .container-offset.cbp-tabs-container {
                    padding: 0;
                }

                .col-tabs, .cbp-hrsub-inner {
                    background: lighten(theme-color(tertiary), 10%);
                    padding: 5px 15px;

                    .cbp-hrsub-tabs-names li[class*="innertab"] a,
                    .cbp-column-title {
                        text-transform: none !important;
                        font-size: 1.2rem;
                        //padding: 18px 0;
                        padding-left: 0;
                        color: theme-color(primary);

                        &::after {
                            float: right;
                            display: block;
                            content: "";
                            background: url("../img/diviser.svg") no-repeat;
                            background-size: 15px 15px;
                            width: 15px;
                            height: 15px;
                            transition: all ease 0.3s;
                            margin-top: -6px;
                        }
                    }
                    [class*="innertab"].active, [class*="innertab"] a.active {
                        &::before, & a::before {
                            display: none;
                        }
                    }
                }
                .cbp-tab-pane {
                    position: absolute;
                    top: 0;
                    left: -100vw;
                    z-index: 2;
                    background: theme-color(white);
                    width: 85vw;
                    height: 100vh;
                    max-width: 85vw;
                    box-shadow: 10px 0px 10vw 0px rgba(theme-color(primary), 0.75);
                    transition: all ease 0.3s;

                    &.show {
                        left: 0;
                    }

                    .cbp-column-title::after {
                        display: none;
                    }

                    .menu_row.menu-element {
                        padding: 30px 15px;

                        .menu_row.menu-element {
                            padding: 0 15px 15px 15px;

                            .cbp-menu-column {
                                margin: 0;
                                border: none;
                            }
                        }
                    }
                    .menu_row.menu-element .cbp-menu-column {
                        margin: 0 0 10px 0;
                        border-bottom: rgba(theme-color(primary), 0.2) solid 1px;
                    }
                }
            }
        }
    }
    .link-phone {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-top: -15px !important;
        margin-bottom: -15px !important;
    }
    .iqitmegamenu-wrapper .cbp-hrmenu .btn-contact {
        display: inline-block !important;
        font-weight: 700 !important;
        position: relative;
        
        &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 1px;
            background: theme-color(primary);
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%);
        }
    }
    .btn-outline-mobile {
        display: block !important;
        line-height: normal !important;
        padding: 0.375rem 0.75rem;
        width: 100%;
        border: theme-color(primary) solid 1px;
        transition: all ease .3s;

        &:hover {
            background: theme-color(primary);
            color: theme-color(white);
        }
    }
}