$font-futura: "Futura";


@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-Light.eot');
    src: url('../fonts/futura-std/FuturaStd-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-Light.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-Light.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-Light.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-LightOblique.eot');
    src: url('../fonts/futura-std/FuturaStd-LightOblique.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-LightOblique.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-LightOblique.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-LightOblique.svg') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-Book.eot');
    src: url('../fonts/futura-std/FuturaStd-Book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-Book.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-Book.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-Book.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-BookOblique.eot');
    src: url('../fonts/futura-std/FuturaStd-BookOblique.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-BookOblique.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-BookOblique.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-BookOblique.svg') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-Medium.eot');
    src: url('../fonts/futura-std/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-Medium.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-Medium.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-Medium.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-MediumOblique.eot');
    src: url('../fonts/futura-std/FuturaStd-MediumOblique.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-MediumOblique.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-MediumOblique.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-MediumOblique.svg') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-Heavy.eot');
    src: url('../fonts/futura-std/FuturaStd-Heavy.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-Heavy.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-Heavy.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-Heavy.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/futura-std/FuturaStd-HeavyOblique.eot');
    src: url('../fonts/futura-std/FuturaStd-HeavyOblique.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura-std/FuturaStd-HeavyOblique.woff') format('woff'),
         url('../fonts/futura-std/FuturaStd-HeavyOblique.ttf') format('truetype'),
         url('../fonts/futura-std/FuturaStd-HeavyOblique.svg') format('svg');
    font-weight: 700;
    font-style: italic;
}