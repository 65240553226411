//ps_imageslider
#ps_imageslider {
    @include media-breakpoint-down(sm) {
        padding: 0;

        .container {
            padding: 0;
        }
    }
    .home-slider {
        position: relative;

        img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 450px;

            @include media-breakpoint-down(sm) {
                height: auto;
            }
        }

        figcaption {
            position: absolute;
            top: 50%;
            left: 60px;
            z-index: 1;
            transform: translateY(-50%);
            width: 50%;
            background: theme-color(white);
            padding: 30px;
            @extend %shadow;

            @include media-breakpoint-down(sm) {
                width: calc(100% - 30px);
                transform: none;
                top: auto;
                bottom: 16px;
                left: 15px;
                padding: 20px;
                position: relative;
            }

            h1, .h1 {
                font-size: 1.6rem;
            }

            p {
                @extend .lead;
            }
        }
    }
}