/*!
 * Bootstrap container offset addon
 * @author Romain Rytter <romain@greentic.net>
 */
.container-offset {
  @include make-container();
  > .row {
    $infixes: ();
    @each $breakpoint, $container-width in $container-max-widths {
      $infixes: append($infixes, breakpoint-infix($breakpoint, $grid-breakpoints));
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 1 through $grid-columns {
          $selector: ('> .col-#{$i}');
          @each $infix in $infixes {
              $selector: append($selector, '> .col#{$infix}-#{$i}', 'comma');
          }
          $half-container-width: $container-width / 2;
          $width: $i * ($container-width / $grid-columns);
          $offset: $half-container-width - $width;
          #{$selector} {
            flex: 0 0 $width;
            max-width: $width;
            &:first-child,
            &:last-child {
              flex: calc(50% - #{$offset});
              max-width: calc(50% - #{$offset});
            }
            &:first-child {
              padding-left: calc(50% - #{$half-container-width});
            }
            &:last-child {
              padding-right: calc(50% - #{$half-container-width});
            }
          }
        }
      }
    }
  }
}