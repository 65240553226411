body#cart {
    .cart-items img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
    .product-line-grid {
        .product-name {
            font-size: 1.1rem;
        }
    }
    .content-cart-empty {
        > [class*="col-"]:last-child {
            @include media-breakpoint-up(md) {
                border-left: theme-color(primary) solid 1px;
            }
            @include media-breakpoint-down(md) {
                border-top: theme-color(primary) solid 1px;
            }
        }
        & + .card {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    .featured-products .products {
        .item {
            padding: 0;
        }
    }
}
body#checkout {
    .payment-option {
        img {
            max-width: 150px;
        }
    }
    .cart-item:not(:last-child) {
        border-bottom: rgba(theme-color(primary), 0.2) solid 1px;
        margin-bottom: 20px;
    }
    .product-line-grid {
        position: relative;
        
        .product-name {
            font-size: 1rem;
        }
        .product-line-grid-body {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-right: 30px !important;
            @extend .col-md-9;
        }
        .product-line-grid-right {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
            max-width: 100%;
            flex: 100%;
        }
        .product-line-grid-right {
            position: static;
        }
        .remove-from-cart {
            font-size: 1.4rem !important;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            span {
                display: none !important;
            }
        }
        .price, .qty {
            flex: 50%;
            max-width: 50%;
        }
        .price {
            text-align: left;
        }

    }
}
body#cart,
body#checkout {
    .featured-products .owl-stage-outer {
        overflow: visible;
    }
    .product-line-grid {
        background: theme-color(white);

        .product-name {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 10px;
            display: block;
        }
        .remove-from-cart {
            font-size: 1.8rem;
            line-height: 1;
            vertical-align: middle;

            span {
                font-size: 1rem;
                vertical-align: middle;
            }
            i {
                vertical-align: middle;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .product-line-grid-right {
            padding-top: 0 !important;
        }
    }
    .block-promo {
        .alert-danger.js-error {
            display: none;
        }
    }
    .col-summary .cart-detailed-totals,
    .cart-summary {
        .card-header {
            background: none;
            border: none;
            text-align: center;
            font-size: 1.6rem;
            line-height: 1.2;
        }
        .label, .value {
            font-weight: 500;
            font-size: 1.1rem;
        }
    }
    .cart-summary-line:not(:last-child), .card-block {
        margin-bottom: 10px;
    }
    .reinsurance-cart {
        .item {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            .title {
                font-size: 1.3rem;
            }
            .content {
                padding-top: 15px;

                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .checkout-step {
        padding-top: 0;
        padding-bottom: 0;

        .step-edit {
            cursor: pointer;
        }

        &.-reachable.-complete {
            overflow: hidden;

            .card-header {
                border-bottom: none;
            }

            .step-edit {
                float: right;
            }
            .content {
                display: none;
            }
        }
        &.-unreachable {
            .step-edit {
                display: none;
            }
            .content {
                display: none;
            }
        }

        &.-reachable.-current {
            .step-edit {
                display: none;
            }
            .content {
                display: block !important;
            }
        }

        &:not(.-current):not(.-complete) {
            background: lighten(theme-color(black), 94%);
            border: none;

            .card-header {
                border: none;
                border-radius: $border-radius;
                color: lighten(theme-color(black), 65%);

                .step-number {
                    background: lighten(theme-color(black), 65%);
                    color: lighten(theme-color(black), 94%);
                }
            }
        }

        .step-title {
            .step-number {
                width: 30px;
                height: 30px;
                background: theme-color(primary);
                color: theme-color(white);
                border-radius: 30px;
                display: inline-block;
                text-align: center;
                margin-right: 8px;
                line-height: 30px;
                font-weight: 900;
            }
            .done {
                display: none;
                color: theme-color(success);
                margin-left: 15px;
                font-size: 1.4rem;
            }
        }
        &.-complete .step-title .done {
            display: inline-block;
        }

        //global style checkout
        .continue {
            width: 100%;
        }
        section {
            padding: 0 40px 40px 0;

            @include media-breakpoint-down(lg) {
                padding: 20px;
            }
        }

        &#checkout-personal-information-step {
            section {
                padding: 40px 40px 40px 0;

                @include media-breakpoint-down(lg) {
                    padding: 20px;
                }
            }

            .nav-with-border {
                border-bottom: lighten(theme-color(black), 80%) solid 1px;
            }
            .nav {
                @include media-breakpoint-down(lg) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    padding-right: 0;
                }

                .nav-item {
                    width: 50%;
                }

                .nav-link {
                    position: relative;
                    z-index: 2;
                    border: lighten(theme-color(black), 80%) solid 1px;
                    border-bottom: none;
                    text-align: center;
                    font-weight: 900;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    background: theme-color(white);
                    margin-bottom: -1px;
                    height: 100%;

                    &:not(.active) {
                        background: theme-color(light);
                        color: lighten(theme-color(black), 85%);
                    }
                    &:last-child {
                        margin-left: -1px;
                    }
                }
            }
        }
    }
}