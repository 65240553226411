body#index {
    .col-img img {
        @include media-breakpoint-up(sm) {
            max-width: 110%;
        }
        @extend %shadow;
    }

    @include media-breakpoint-down(sm) {
        main {
            .w-100:not(.btn) {
                display: none !important;
            }
        }
    }
    
    #custom-text {
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            .col-text {
                column-count: 2;
                column-gap: 15px;
            }
        }

        a {
            @extend %link-underline;
        }
    }

    #home-magazine {
        .img-cover-magazine {
            max-width: 120%;
            margin-left: -20%;
            object-fit: cover;
            object-position: center center;
            height: calc(100% + 60px);
            @extend %shadow;
        }

        @include media-breakpoint-down(sm) {
            .item-article-post:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        article {
            display: flex;
            align-items: stretch;
            flex-direction: column;
            height: 100%;

            header {
                a {
                    display: block;

                    img {
                        object-fit: cover;
                        object-position: center center;
                        width: 100%;
                        height: 150px;
                    }
                }
            }
            section {     
                flex: 1 1 auto;

                time, .resume {
                    display: block;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .title a {
                    color: theme-color(white);
                }
            }
            footer {
                width: 100%;
                text-align: right;
                align-self: end;
            }
        }
    }
}