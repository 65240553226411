



/* sub-menu */
.cbp-hrmenu {
    &.cbp-arrowed .cbp-triangle-container {
        display: block;
    }
    .cbp-triangle-top {
        width: 0;
        height: 0;
        position: absolute;
        z-index: 1;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #fff;
        top: 1px;
    }
    .cbp-triangle-top-back {
        width: 0;
        height: 0;
        position: absolute;
        left: -1px;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 13px solid #EAEAEA;
    }
    .cbp-triangle-container {
        display: none;
        left: 0px;
        top: 0px;
        position: absolute;
    }
    .cbp-triangle-left,
    #columns .cbp-hrmenu .cbp-triangle-left {
        position: absolute;
        width: 0;
        height: 0;
        left: 1px;
        z-index: 1;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #fff;
    }
    .cbp-triangle-left-back,
    #columns .cbp-hrmenu .cbp-triangle-left-back {
        position: absolute;
        width: 0;
        height: 0;
        top: -1px;
        left: 0px;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-right: 13px solid #EAEAEA;
    }
    .cbp-hrsub {
        visibility: hidden;
        opacity: 0;
        position: absolute !important;
        display: none;
        padding-bottom: 20px;
        padding: 0px !important;
    }
    .cbp-hrsub-inner {
        background: #fff;
        border: 1px solid #EAEAEA;

        > .row {
            /*margin-left: 10px;
            margin-right: 10px;*/

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
    .cbp-notfit {
        right: 0px;
    }
}

.cbp-horizontal.cbp-arrowed .cbp-triangle-container {
    position: absolute;
    bottom: -13px;
}

.cbp-vertical.cbp-arrowed .cbp-hrsub-inner {
    margin-left: 12px;
}

.cbp-horizontal.cbp-arrowed .cbp-hrsub-inner {
    margin-top: 12px;
}

.cbp-hropen .cbp-hrsub {
    visibility: visible;
    opacity: 1;
    z-index: 1030;
}

.cbp-hrsub-wide .cbp-hrsub-inner .container > .row:last-child {
    margin-bottom: 20px;
}

.cbp-hrmenu .cbp-show {
    display: block;
}
.cbp-category-link-w {
    position: relative;

}
.cbp-category-tree {
    display: inline-block;
}
.cbp-hrmenu ul.cbp-hrsub-level2 {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 100%;
    margin-top: -20px;
    padding: 8px 10px;
    z-index: 3;
    width: auto;
    background: #fff;
    border: 1px solid #EAEAEA;
    white-space: nowrap;
}
.cbp-hrmenu ul.cbp-hrsub-level3 {
    left: 101%;
}
.cbp-hrmenu .pull-right {
    float: right !important;
}
.cbp-hrsub-haslevel3 > .cbp-category-link-w > ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 100px;
    margin-top: -20px;
    padding: 8px 10px;
    z-index: 3;
    width: auto;
    transition: visibility 180ms ease-in-out,opacity 180ms ease-in-out;
    white-space: nowrap;
    left: 101%;
}
.cbp-hrsub-haslevel3:hover > .cbp-category-link-w  > ul {
    visibility: visible;
    opacity: 1.0;
}

.cbp-hrsub-haslevel3:hover .cbp-hrsub-level2 {
    visibility: visible;
    opacity: 1.0;
}
.cbp-hrsub-haslevel2:hover > div > ul.cbp-hrsub-level2 {
    visibility: visible;
    opacity: 1.0;
}

.cbp-hrmenu .cbp-tab-pane {
    display: none;
}
/*.cbp-hrmenu .cbp-tab-pane > .clearfix > .row {
    margin: 0px;
}*/
.cbp-hrmenu .cbp-tab-pane > div > .row:last-child {
    margin-bottom: 20px;
}

.cbp-hrmenu .cbp-hropen .cbp-tab-pane.active {
    visibility: visible;
    opacity: 1.0;
}

.cbp-hrmenu .cbp-tab-pane.active {
    display: table-cell;
}

/* wide submenu wrapper  */
.cbp-hor-width-0 .cbp-hrsub-wide .cbp-hrsub {
    width: 100%;
    left: 0;
}

/* wide submenu submenucontent  */
.cbp-hrsub-wide.cbp-hrsub-widec .cbp-hrsub-inner {
    width: 100%;
    max-width: 100%;
}

/* submenu effects  */
.cbp-fade .cbp-hrsub {
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
}

.cbp-fade .cbp-hrsub-level2 {
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
}

.cbp-fade .cbp-hrsub-wrapper {
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
}

.cbp-fade.cbp-vertical > ul {
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
}

.cbp-fade-slide-bottom .cbp-hrsub {
    transition: all 300ms ease-in-out;
    margin-top: 15px;
}

.cbp-fade-slide-bottom .cbp-hropen .cbp-hrsub {
    transition: all 300ms ease-in-out;
    margin-top: 0px;
}

.cbp-fade-slide-bottom .cbp-hrsub-level2 {
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
}

.cbp-fade-slide-top .cbp-hrsub {
    transition: all 300ms ease-in-out;
    margin-top: -15px;
    z-index: -20;
}

.cbp-fade-slide-top .cbp-hropen .cbp-hrsub {
    transition: all 300ms ease-in-out;
    margin-top: 0px;
    z-index: 1;
}

.cbp-fade-slide-top .cbp-hrsub-level2 {
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
}

.cbp-hrmenu .cbp-hrsub-inner:before,
.cbp-hrmenu .cbp-hrsub-inner:after {
content: "";
    display: table;
}

.cbp-hrmenu .cbp-hrsub-inner:after {
    clear: both;
}

.cbp-hrsub h4 {
    color: #afdefa;
    padding: 2em 0 0.6em;
    margin: 0;
    font-size: 160%;
    font-weight: 300;
}

@media (min-width: 1000px) {
    .cbp-spmenu-push-toright {
        left: 0px !important;
    }
}