/*!
 * Bootstrap fixed columns addon
 * @author Romain Rytter <romain@greentic.net>
 */
$infixes: ();
@each $breakpoint, $container-width in $container-max-widths {
    $infixes: append($infixes, breakpoint-infix($breakpoint, $grid-breakpoints));
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 1 through $grid-columns {
            $selector: ('.col-fixed.col-#{$i}');
            @each $infix in $infixes {
                $selector: append($selector, '.col-fixed.col#{$infix}-#{$i}', 'comma');
            }
            #{$selector} {
                $width: $i * ($container-width / $grid-columns);
                flex: 0 0 $width;
                max-width: $width;
            }
        }
    }
}