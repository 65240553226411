.item-categories {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    section {
        text-align: center;
        padding: 25px;
        background: theme-color(white);
        color: theme-color(primary);
        width: calc(100% - 30px);
        position: relative;
        z-index: 1;
        margin: -30% auto 0 auto;
        @extend %shadow;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        .h3, h3 {
            text-transform: uppercase;
            margin: 0;
            padding: 0;
        }

        header {
            padding-bottom: 100%;
        }

        .resume {
            margin: 15px 0;
        }
    }
}