@for $i from 1 through 6 {
    h#{$i}, .h#{$i} {
        font-weight: 500;
        letter-spacing: 1px;
    }
}

h1, .h1 {
    font-size: 2.2rem;
}

h2, .h2 {
    margin-bottom: 20px;
    font-size: 1.7rem;

    &::after {
        content: "";
        display: block;
        height: 1px;
        width: 120px;
        background: theme-color(primary);
        margin-top: 20px;
    }
    &.text-center {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
}

@each $name, $color in $theme-colors {
    &.bg-#{$name} {
        h2::after, .h2::after {
            background: theme-text-color($color);
        }
    }
}

h3, .h3 {
    font-size: 1.2rem;
}

h4, .h4 {
    font-size: 1.1rem;
}