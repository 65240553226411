.product-miniature {
    background: theme-color(light);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;

    header, section {
        width: 100%;
    }
    section {
        flex: 1 1 auto;
    }

    header {
        position: relative;

        .product-flags {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                background: theme-color(secondary);
                color: theme-color(primary);
                text-transform: uppercase;
                padding: 5px 10px;
                font-weight: 600;
                text-align: center;
                display: table;
            }
        }

        a.thumbnail {
            display: block;
        }
    }
    section, footer {
        padding: 20px;
        padding-bottom: 0;

        .product-title {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 500;

            .attributs {
                text-transform: none;
                font-size: 0.8rem;
                margin-top: 10px;
            }
        }
        .regular-price, .price {
            color: theme-color(quaternary);
            font-weight: 700;
        }
        .regular-price {
            text-decoration: line-through;
            font-size: 0.9rem;
        }
    }
    footer {
        padding-top: 0;
    }
}
.miniature {
    .product-miniature {
        section {
            padding: 10px;
    
            .product-title {
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 500;
            }
        }
    }
}

.itemProductListArticle > div {
    height: 100%;
}