.form-control {
    background: theme-color(light);
    border-color: rgba(theme-color(primary), 0.3);
    border-width: 2px;

    &.form-control-lg {
        font-size: 1rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
}
.label-button {
    input {
        display: none;

        & + label {
            cursor: pointer;
            @extend .btn;
            border-radius: 5px;
            margin: 5px;
            text-transform: none !important;
        }

        &:not(:checked) + label {
            @extend .btn-outline-primary;
        }

        &:checked + label {
            @extend .btn-primary;
        }
    }
}
.bootstrap-touchspin {
    input[type=number] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
    }
}


.grecaptcha-badge {
  display: none!important;
}