#home-categories {
    overflow: hidden;

    .bg-gray {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    header, img {
        width: 100%;
    }

    .categories-slider .owl-stage-outer {
        overflow: visible;
    }
}