

/* vertical style  */
.cbp-hrmenu.cbp-vertical > ul > li {
    float: none;
    display: block;
}

.cbp-vertical-on-top {
    float: left;
    width: 250px;
    position: relative;
}
.cbp-vertical-on-top > .cbp-vertical > ul {
    position: absolute;
    visibility: hidden;
    opacity: 0;
}
.cbp-vertical-on-top.cbp-vert-expanded > .cbp-vertical > ul {
    visibility: visible;
    opacity: 1;
}
.cbp-vertical-on-top nav#cbp-hrmenu1 {
    margin-bottom: 0px;
}

#index .cbp-homepage-expanded > .cbp-vertical > ul {
    visibility: visible;
    opacity: 1;
}

#index .cbp-sticky .cbp-homepage-expanded > .cbp-vertical > ul {
    visibility: hidden;
    opacity: 0;
}

#index .cbp-sticky .cbp-vert-expanded > .cbp-vertical > ul {
    visibility: visible;
    opacity: 1;
}

.cbp-hrmenu.cbp-vertical {
    width: 100%;
    margin-bottom: 20px;
}
.cbp-hrmenu.cbp-vertical .cbp-submenu-aindicator {
    position: absolute;
    right: 8px;
    margin-top: 1px;
}
.cbp-hrmenu.cbp-vertical .cbp-legend-vertical {
    top: -8px;
    right: 14px;
}

.cbp-hrmenu.cbp-vertical > ul {
    width: 100%;
    max-width: 100%;
    padding: 0px;
}

.cbp-hrmenu.cbp-vertical > ul > li > a, .cbp-hrmenu.cbp-vertical > ul > li .span.cbp-main-link {
    padding-left: 15px;
    padding-right: 15px;
    display: block;
}
.cbp-hrmenu.cbp-vertical > ul > li:first-child > a, .cbp-hrmenu.cbp-vertical > ul > li:first-child .span.cbp-main-link {
    border-top: none;
}

.cbp-hrmenu.cbp-vertical .cbp-hrsub-wrapper {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
}

.cbp-hrmenu.cbp-vertical .cbp-hropen .cbp-hrsub-wrapper {
    visibility: visible;
    opacity: 1.0;
}