.block-breadcrumb {
    .btn-return-breadcrumb {
        display: inline-block;
        float: left;
        margin-right: 15px;
        cursor: pointer;
    }
    .breadcrumb {
        background: none;
        padding: 0.25rem 0 0 0;
        margin: 0;

        li a, li span, li strong {
            color: theme-color(primary);
            font-weight: 500;
            font-size: 1rem;
        }
        .breadcrumb-item + .breadcrumb-item::before {
            color: lighten(theme-color(gray), 40%);
            font-weight: 600;
            width: 5px;
            padding-right: 0;
        }
    }
}