body#pagenotfound {
    main {
        #main {
            padding: 0;
        }
        img {
            object-fit: cover;
            object-position: center center;
            background: theme-color(grey);
            height: 70vh;
        }
        .content-404 {
            padding: 120px;

            @include media-breakpoint-down(md) {
                padding: 30px;
            }
        }
        .img-404 {
            position: relative;

            .title {
                position: absolute;
                top: 60px;
                left: 60px;
                z-index: 1;
                color: theme-color(white);
                font-size: 3.5rem;

                span {
                    display: block;
                    font-size: 2rem;

                    i {
                        font-size: 3.5rem;
                        vertical-align: middle;

                        &::before {
                            vertical-align: initial;
                        }
                    }
                }
            }
        }
    }
}