.cursor-owl {
    width: 0;
    height: 0;
    background: url('../img/cursor-owl-carousel.svg') center center;
    background-size: cover;
    position: absolute;
    z-index: 9999;
    visibility: hidden;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.8s;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    pointer-events: none;
    mix-blend-mode: difference;

    &.active {
        visibility: visible;
        width: 90px;
        height: 90px;
    }
}

.owl-theme {
    .owl-stage-outer {
        overflow: visible;
        padding-bottom: 30px;
    }
    /*.owl-nav {
        button {
            position: absolute;
            bottom: -15px;
            z-index: 1;

            &:hover {
                background: none !important;
            }

            &.owl-prev {
                left: 0;
            }
            &.owl-next {
                right: 0;
            }

            i {
                font-size: 2rem;
            }
        }
    }*/
    .owl-dots {
         .owl-dot {
            height: 10px;
            background: transparent;
            width: 20%;

            span {
                background: rgba(theme-color(primary), 0.2);
                border-radius: 0;
                width: 100%;
                height: 1px;
                margin: 0;
            }
            &.active span {
                background: theme-color(primary);
                height: 3px;
            }
        }
    }

    .owl-stage, .owl-stage, .item {
        display: flex;
        height: 100%;
    }
}

.bg-primary, .bg-gray {
    .owl-theme {
        .owl-dots {
             .owl-dot {
                span {
                    background: rgba(theme-color(white), 0.2);
                }
                &.active span {
                    background: theme-color(white);
                }
            }
        }
    }
}