#cookie-banner {
	position: fixed;
	z-index: 1000;
	bottom: 50px;
	left: 50px;
    width: 360px;
    font-size: 0.9rem;
    font-weight: 500;
    color: theme-color(primary);
    background: theme-color(secondary);
	text-align: center;
    padding: 20px;
    border-radius: $border-radius;
    @extend %transition;
    
    @media(max-width: 460px) {
        bottom: 15px;
        left: 15px;
        width: calc(100% - 30px);
    }

    .btn {
        cursor: pointer;
    }
}