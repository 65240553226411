#search_widget {
    form {
        .form-group {
            position: relative;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            i {
                font-size: 1.6rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        & {
            position: fixed;
            top: 0;
            left: -100vw;
            z-index: 1000;
            width: 100vw;
            height: 100vh;
            padding: 30px;
            padding-top: 90px;
            background: theme-color(light);
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
        }
        &.open {
            left: 0;

            .close-search-mobile {
                position: absolute;
                top: 30px;
                right: 30px;
                z-index: 1;
                text-transform: uppercase;
                text-align: right;
                cursor: pointer;
            }

            label {
                display: block;
                text-align: left;
                font-weight: 500;
                color: theme-color(primary);
                font-size: 1.2rem;
            }
        }
    }
}