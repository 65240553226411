.iqitmegamenu-wrapper {
    z-index: 97;
    letter-spacing: 1px;

    .iqitmegamenu > .container {
        padding: 0;
    }
    #cbp-hrmenu {
        border-top: rgba(theme-color(primary), 0.3) solid 2px;
    }
}
.iqitmegamenu-wrapper.cbp-hor-width-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.cbp-hor-width-1 .container {
    padding-left: 0px;
    padding-right: 0px;
}

.iqitmegamenu {
    background: theme-color(white);
}

.cbp-sticky.cbp-sticky-transparent {
    opacity: 0.85;
    transition: opacity 300ms ease-in-out;

    &:hover {
        opacity: 1;
    }
}

#iqitmegamenu-horizontal.cbp-sticky {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    box-shadow: 0 1px 2px rgba(theme-color(primary), 0.15);
    z-index: 90;
    left: 0;

    @include media-breakpoint-down(lg) {
        #iqitmegamenu-horizontal.cbp-sticky {
            position: static !important;
        }
    }

    .cbp-hrmenu-tab > a > .cbp-legend {
        display: none;
    }    
}

.cbp-hrmenu {
    width: 100%;

    @include media-breakpoint-down(lg) {
        .cbp-hrmenu {
            display: none;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .col-xs-12 {
        width: 100%;
    }
    .cbp-tab-title {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
    }
    .cbp-manufacturers {
        margin-top: -10px;

        li {
            margin-top: 10px;

            &:hover {
                opacity: 0.85;
            }
        }
    }
    .cbp-vertical-title {
        transition: all 300ms ease-in-out;
        padding: 0px 15px;

        .icon {
            margin-right: 10px;
        }
    }
    .cbp-categories-row {
        margin-top: -10px;

        & > div {
            margin-top: 10px;
        }
    }

    .menu_row {
        position: static;
    }
    .cbp-empty-column .cbp-menu-column-inner > .menu_row:first-child {
        margin-top: -20px;
    }
    .cbp-tabs-container {
        width: 100%;
    }
    .cbp-absolute-column {
        margin: 0px;
        padding: 0px;
        position: absolute;
        width: auto !important;
        z-index: 2;
    }
    .cbp-column-title {
        display: block;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: 600;

        .cbp-legend.cbp-legend-inner {
            top: -2px;

            .cbp-legend-arrow:before {
                top: -1px;
            }
        }

        &.cbp-column-title-inline {
            padding: 0px !important;
            border: none  !important;
            margin: 0px  !important;
            margin-right: 10px  !important;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .cbp-menu-column {
        margin-top: 20px;

        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .cbp-mainlink-iicon,
    .cbp-mainlink-icon {
        width: auto;
        display: inline;
        vertical-align: middle;
        margin-right: 4px;
    }
    .cbp-onlyicon .cbp-mainlink-iicon,
    .cbp-onlyicon .cbp-mainlink-icon {
        margin-right: 0px;
    }
    .cbp-legend {
        display: inline-block;
        top: -15px;
        right: 5px;
        background-color: theme-color(dark);
        color: theme-color(white);
        position: absolute;
        font-weight: normal;
        font-size: 10px;
        padding: 4px 6px;
        text-transform: none;
        box-shadow: 0 1px 2px rgba(theme-color(primary), 0.15);
        line-height: initial;
        
        .cbp-legend-arrow:before {
            font-family: "FontAwesome";
            content: "\F0D7";
            font-size: 15px;
            position: absolute;
            bottom: -10px;
            right: 5px;
        }
    }
    .cbp-legend.cbp-legend-inner {
        position: relative;
        top: 0px;
        margin-left: 5px;
        right: 0px;
        padding: 2px 4px;
        vertical-align: middle;

        .cbp-legend-arrow:before {
            content: "\f0d9";
            bottom: 0px;
            top: 0px;
            margin-top: 1px;
            z-index: 2;
            right: auto;
            left: -4px;
        }
    }
    .cbp-tab-content {
        border-left: 1px solid theme-color(light);
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        padding: 0px;
        vertical-align: top;
    }
    
    .cbp-products-big {
        * {
            box-sizing: border-box;
        }
        .quick-view {
            font-size: 0px;
        }
        .online-label,
        .new-label,
        .sale-label {
            box-sizing: content-box;
        }
        li.ajax_block_product {
            text-align: center;
            margin-bottom: 10px;

            &:hover {
                padding-top: 0px;
                padding-bottom: 0px;

                .functional-buttons-grid {
                    visibility: visible;
                    opacity: 0.9;
                    margin-bottom: 0px;
                }
            }
        }
    }
    .cbp-products-list {
        .product-name,
        .product-name-container {
            height: auto;
            line-height: inherit;
        }
        > li {
            padding-top: 9px;
            padding-bottom: 9px;
        }
        .product-image-container {
            float: left;
            margin-right: 10px;
            max-width: 60px;
            min-width: 30px;
        }
    }

    .col-tabs {
        background: theme-color(tertiary);
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 30px;
    }
    .menu_row.menu-element  {
        @include media-breakpoint-up(lg) {
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 30px;
        }

        .cbp-column-title, .cbp-column-title a {
            line-height: 1.1 !important;
        }

        .menu_row.menu-element {
            padding: 0;

            .cbp-column-title, .cbp-column-title a {
                text-transform: none !important;
            }
        }
    }

    .cbp-hrsub-tabs-names {
        li {
            position: relative;

            &[class*="innertab"] {
                a {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 1.05rem;
                    padding: 15px 0;
                    @extend %transition;

                    &::before {
                        content: "";
                        display: inline-block;
                        background: theme-color(primary);
                        width: 0;
                        height: 2px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        z-index: 1;
                        transform: translateY(-50%);
                        @extend %transition;
                    }
                }

                &.active a, & a.active {
                    position: relative;
                    padding-left: 20px;

                    &::before {
                        width: 15px;
                    }
                }
            }

            .cbp-inner-border-hider {
                position: absolute;
                top: 0px;
                background: white;
                display: block;
                height: 100%;
                z-index: 1;
                display: none;
            }

            &.active .cbp-inner-border-hider {
                display: block;
            }
            a {
                position: relative;
                display: block;
                transition: none;
                padding: 14px 16px;
            }
        }
    }
    &.cbp-submenu-notarrowed .cbp-submenu-aindicator {
        display: none;
    }
    .cbp-submenu-aindicator {
        margin-left: 3px;
    }
    .cbp-submenu-it-indicator {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 10px;
        margin: auto;
        height: 15px;
    }
    &.cbp-menu-centered > ul {
        text-align: center;

        > li {
            text-align: left;
            float: none;
        }
    }
    .cbp-links {
        li {
            margin: 3px 0;
            padding-left: 2px;
            position: relative;

            &:last-child {
                margin-bottom: 0px;
            }

            &.cbp-hrsub-haslevel2 > div > a:after,
            .cbp-hrsub-haslevel3 > div > a:after {
                content: "\f0da";
                display: inline;
                font-family: "FontAwesome";
                padding-left: 4px;
                opacity: 0.2;
                position: relative;
                transition: opacity 0.3s ease;
            }

            a {
                padding-left: 10px;
                display: inline-block;

                &:before {
                    content: "\f105";
                    display: inline;
                    font-family: "FontAwesome";
                    position: absolute;
                    left: 0px;
                    transition: left 0.3s ease, right 0.3s ease;
                }
                &:hover:before {
                    left: 3px;
                }
            }
        }
        &.cbp-valinks-vertical li {
            display: inline-block;
            margin: 0px;
            margin-right: 12px;
            padding: 0px;
        }
        &.cbp-valinks-vertical.cbp-valinks-vertical2 {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .cbp-category-thumb{
        display: block;
        margin-bottom: 10px;
    }
}

.btn-all-category {
    background: theme-color(light);
    text-align: right;
    padding: 20px;
    margin: -50px 0 0 -45px;
    font-weight: 600;

    @include media-breakpoint-down(lg) {
        margin: 0;
    }

    a {
        @extend %link-underline;
    }
}