body#product {
    @import "product/product-modal-custom-prismaflex";

    .product-cover {
        position: relative;

        .btn-favorite-content {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 10;

            a {
                transition: none !important;
                cursor: pointer;
                display: block;
                background: transparent;
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 100%;
                border: theme-color(primary) solid 2px;
                text-align: center;
            }
            i {
                line-height: inherit !important;
            }
            button {
                border: none !important;
                padding: 0 !important;
                background: transparent !important;
                transition: none !important;
            }
        }

        .product-flags {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                background: theme-color(secondary);
                color: theme-color(primary);
                text-transform: uppercase;
                padding: 5px 10px;
                font-weight: 600;
                text-align: center;
                display: table;
            }
        }
    }
    .thumb-container {
        cursor: pointer;
        
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        img {
            border: transparent solid 2px;

            &.selected {
                border-color: theme-color(primary);
            }
        }
    }
    
    .ps_categoryproducts {
        .item {
            padding: 0;
        }
    }

    #accordionProduct {
        .btn-collapse {
            padding: 15px;
            border-top: theme-color(primary) solid 1px;
            font-size: 1.4rem;
            cursor: pointer;
            position: relative;
            font-weight: 500;

            &::before {
                content: "";
                background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZD0iTSA0LjIxODc1IDEwLjc4MTI1IEwgMi43ODEyNSAxMi4yMTg3NSBMIDE1LjI4MTI1IDI0LjcxODc1IEwgMTYgMjUuNDA2MjUgTCAxNi43MTg3NSAyNC43MTg3NSBMIDI5LjIxODc1IDEyLjIxODc1IEwgMjcuNzgxMjUgMTAuNzgxMjUgTCAxNiAyMi41NjI1IFoiLz48L3N2Zz4=);
                width: 15px;
                height: 15px;
                display: block;
                position: absolute;
                top: 20px;
                right: 15px;
                z-index: 1;
                @extend %transition;
            }

            &[aria-expanded=true]::before {
                transform: rotate(180deg);
            }

            .num-comment {
                font-weight: 500;
                color: theme-color(quaternary);
            }
        }
        .label {
            font-weight: 500;
        }
    }

    .author {
        border-right: rgba(theme-color(gray), 0.3) solid 1px;
        padding-right: 5px;
        margin-right: 5px;
        display: inline-block;
    }
    .author, .reference {
        font-weight: 300;
        font-size: 0.8rem;
    }

    .short-description {
        border-left: theme-color(primary) solid 1px;
        padding-left: 30px;

        a:not(.btn) {
            @extend %link-underline;
        }
    }

    .product-description {
        a:not(.btn) {
            @extend %link-underline;
        }
    }

        .col-add-to-cart {
        background: theme-color(white);
        @extend %shadow;

        h1 {
            font-size: 1rem;
            color: theme-color(gray);
            font-weight: 500;
            margin: 0;
            margin-bottom: 10px;
            padding: 0;
        }

        .title-product {
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.2;
        }
        .item {
            padding: 20px;
        }
        
        .product-prices {
            .tax-shipping-delivery-label, .product-price, .product-pack-price {
                display: inline-block;
            }
            .regular-price, .price, .current-price {
                color: theme-color(quaternary);
                font-weight: 600;
                font-size: 1.6rem;
            }
            .regular-price {
                text-decoration: line-through;
                font-size: 0.9rem;
            }
        }

        .control-label {
            font-weight: 500;
            margin-bottom: 5px;
            display: block;
        }

        .product-variants-item {
            .group-color {
                .input-container {
                    display: inline-block;
                }
                .input-color {
                    display: none;

                    &:checked + .color{
                        border-color: theme-color(primary);
                    }
                }
                .color {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    border: rgba(theme-color(primary), 0.3) solid 1px;

                    span {
                        display: none;
                    }
                }
            }
        }

        .blockreassurance_product,
        #block-reassurance {
            margin: 0;
            padding: 20px;
            background: theme-color(primary);
            color: theme-color(white);
            
            ul {
                list-style-type: none;
                padding-left: 0;
                margin: 0;
            }
            .item-reasurance,
            li {
                display: table;
                 
                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                .icon, .text {
                    display: table-cell;
                    vertical-align: middle;
                }
                .icon {
                    width: 55px;
                    padding-right: 15px;
                }
                .title, .description {
                    font-weight: 600;
                }
                .description {
                  font-weight: 300;
                  font-size: 12px;
                }
                .title {
                    text-transform: uppercase;
                    line-height: 1.1;
                    font-size: 14px;
                    margin-bottom: 1px;
                }
            }
            .item-reasurance img.svg {
                filter: invert(1)!important;
                visibility: visible!important;
            }
        }
        .change-product {
            .title {
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 1.1;
                margin-bottom: 15px;
            }
            .content {
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0 -5px;
                    display: flex;

                    li {
                        text-align: center;
                        display: inline-block;
                        width: 25%;

                        i {
                            font-size: 3rem;
                            display: block;
                            margin-bottom: 5px;
                        }
                        a {
                            position: relative;
                            display: inline-block;
                            font-size: 0.9rem;
                            line-height: 1.1;
                            font-weight: 500;
                            padding: 5px;
                            height: 100%;
                            @extend %transition;

                            &:hover {
                                background: theme-color(light);
                            }
                        }        
                    }
                }
            }
        }
    }
    #blockcart-modal {
        .product-name {
            text-transform: uppercase;
            font-weight: 500;
        }
        .product-price, .price {
            font-weight: 500;
            color: theme-color(quaternary);
        }
        .label {
            font-weight: 500;
        }
    }

    .product-customization {
        .card {
            padding: 20px;
        }
        ul {
            list-style-type: none;
            padding-left: 0;

            li:not(:last-child) {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: theme-color(gray) solid 1px;
            }

            label {
                display: block;
                margin-bottom: 5px;
                font-weight: 600;
            }
            .custom-file {
                display: block;
                height: auto !important;
                
                & + small {
                    margin-top: 5px;
                    display: block;
                }
            }
        }
        input, textarea {
            @extend .form-control;
        }
    }
}