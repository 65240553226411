.btn {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;

    /*&:not(.btn-sm):not(.btn-lg):not(.btn-touchspin) {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }*/

    &.btn-lg {
        font-size: 1rem;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
    }
}