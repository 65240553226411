@font-face {
	font-family: "is";
	src: url("/themes/scenolia-theme/assets/icons/is.eot?#iefix") format("embedded-opentype"),
url("/themes/scenolia-theme/assets/icons/is.woff") format("woff"),
url("/themes/scenolia-theme/assets/icons/is.woff2") format("woff2"),
url("/themes/scenolia-theme/assets/icons/is.ttf") format("truetype"),
url("/themes/scenolia-theme/assets/icons/is.svg#is") format("svg");
}

.is {
	line-height: 1;
}

.is:before {
	font-family: is !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.is-arrow-left:before {
	content: "\f101";
}
.is-arrow-right:before {
	content: "\f102";
}
.is-brise-vue:before {
	content: "\f103";
}
.is-cart:before {
	content: "\f104";
}
.is-categorie-brise-vue:before {
	content: "\f105";
}
.is-categorie-papier-peint:before {
	content: "\f106";
}
.is-categorie-poster:before {
	content: "\f107";
}
.is-categorie-tableau:before {
	content: "\f108";
}
.is-categorie-tenture-murale:before {
	content: "\f109";
}
.is-frame:before {
	content: "\f10a";
}
.is-heart-o:before {
	content: "\f10b";
}
.is-heart:before {
	content: "\f10c";
}
.is-poster:before {
	content: "\f10d";
}
.is-scene:before {
	content: "\f10e";
}
.is-square-four:before {
	content: "\f10f";
}
.is-square:before {
	content: "\f110";
}
.is-user:before {
	content: "\f111";
}
.is-wallpaper:before {
	content: "\f112";
}
