footer#footer {
    .footer-container {
        .container {
            @extend section;
            font-weight: 500;
        }
        a:not(.btn):not(.dropdown-item) {
            color: theme-color(white);
        }
    }

    .title-footer {
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 1.15rem;

        @include media-breakpoint-down(sm) {
            cursor: pointer;
        }

        span {
            display: block;
        }

        i {
            font-size: 1rem;
            float: right;
            @extend %transition;
        }

        &.open i {
            transform: rotate(180deg);
        }
    }
    .content-toggle {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        margin-bottom: 30px;

        ul {
            list-style-type: none;
            padding: 0;
        }

        .phone, .fax {
            font-weight: 900;
        }
        .form-link {
            text-decoration: underline;
        }

        .form-control {
            border: none;
        }
    }
    @include media-breakpoint-down(md) {
        .open + .content-toggle {
            display: block;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    .block-social {
        text-align: center;
        padding: 30px 0;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;

            li {
                margin: 0 5px;

                a {
                    color: theme-color(white);
                    @extend .lab;
                    font-size: 1.8rem;
                }
    
                &.facebook a {
                    @extend .la-facebook-f;
                }
                &.pinterest a {
                    @extend .la-pinterest-p;
                }
                &.instagram a {
                    @extend .la-instagram;
                }
                &.youtube a {
                    @extend .la-youtube;
                }
            }
        }
    }

    .copyright {
        border-top: theme-color(white) solid 1px;
        padding-top: 30px;
    }
}