
body#contact {
    .contact-rich {
        .block {
            display: table;

            .icon, .data {
                display: table-cell;
                vertical-align: middle;
            }
            .icon {
                width: 45px;
                padding-right: 15px;
                font-size: 2rem;
            }
        }
    }
}