#header-informations {
    background: theme-color(primary);
    color: theme-color(white);
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1.2;

    a {
        color: theme-color(white);
        text-decoration: none;
    }

    span {
        font-size: 0.9rem;
        text-transform: none;
        display: block;
    }

    .owl-stage-outer {
        padding: 0;
    }

    .container {
        overflow: hidden;
    }

    @include media-breakpoint-down(md) {
        .row {
            margin: 0 !important;
        }
        .item {
            display: block;

            i {
                float: left;
                font-size: 2.3rem;
                margin-right: 10px;
            }
        }
    }
}