/* Responsive styling*/
#iqitmegamenu-mobile {
  display: none;
  text-align: left;
}
@media (max-width: 999px) {
  #iqitmegamenu-mobile {
    display: block;
  }
}
#iqitmegamenu-mobile ul, #iqitmegamenu-mobile li {
  list-style-type: none;
}
#iqitmegamenu-mobile #iqitmegamenu-shower {
  text-transform: uppercase;
  padding: 12px 15px;
  cursor: pointer;
  line-height: 36px;
}
#iqitmegamenu-mobile .iqitmegamenu-icon {
  margin-right: 10px;
  color: #000;
  display: block;
  background-color: theme-color(white);
  font-weight: bold;
  font-size: 24px;
  float: left;
  width: 36px;
  text-align: center;
  cursor: pointer;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion {
  list-style: none;
  padding: 0px;
  overflow: hidden;
  height: auto;
  max-height: 0px;
  margin: 0px;
  transition: all 0.5s ease 0s;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion.showedmenu {
  border-top: none;
  padding: 12px;
  max-height: 9999px;
  transition: all 0.5s ease 0s;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion > li {
  border-bottom: 1px dashed #dddddd;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion > li:last-child {
  border-bottom: none;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion > li > a {
  text-transform: uppercase;
  display: block;
  padding: 10px 0;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion > li ul {
  background-color: #f8f8f8;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion > li ul a {
  display: block;
  padding: 10px 0;
  border-top: 1px dashed #dddddd;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion ul {
  list-style: none;
  padding: 0 0 0 0;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion ul li {
  font-weight: normal;
  cursor: auto;
  padding: 0 0 0 7px;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion a {
  text-decoration: none;
}
#iqitmegamenu-mobile .iqitmegamenu-accordion a:hover {
  text-decoration: none;
}  
#iqitmegamenu-accordion {
  display: none;
}
#iqitmegamenu-accordion div.responsiveInykator {
  display: block;
  font-weight: bold;
  font-size: 24px;
  float: right;
  padding: 6px 0;
  position: relative;
  top: 7px;
  width: 32px;
  text-align: center;
  cursor: pointer;
}
#iqitmegamenu-accordion > li ul {
  display: none;
}
 
@media (max-width: 999px) {
  #iqitmegamenu-accordion {
    display: block;
  }
}
  
#iqitmegamenu-accordion.cbp-spmenu div.responsiveInykator {
  border: none;
}
#iqitmegamenu-accordion.cbp-spmenu li a:hover {
  box-shadow: inset -134px 0px 10px -133px rgba(0, 0, 0, 0.4);
}
#iqitmegamenu-accordion.cbp-spmenu > li a {
  text-transform: uppercase;
  font-weight: bold;
}
#iqitmegamenu-accordion.cbp-spmenu > li ul{
  padding: 0px 10px;
  display: none;
  font-size: 90%;
  box-shadow: inset -134px 0px 10px -133px rgba(0, 0, 0, 0.4);
}
  
#iqitmegamenu-accordion  > li ul.cbpm-ul-showed {
  display: block;
}  
#iqitmegamenu-accordion.cbp-spmenu > li ul ul {
  box-shadow: none;
}
#iqitmegamenu-accordion.cbp-spmenu > li ul ul a:hover {
  box-shadow: none;
}
#iqitmegamenu-accordion.cbp-spmenu > li ul div.responsiveInykator {
  background: none;
  margin-right: 0px;
  color: #fff;
}
#iqitmegamenu-accordion.cbp-spmenu > li ul ul {
  margin: 0px;
}
#iqitmegamenu-accordion.cbp-spmenu > li ul ul a {
  text-transform: none;
  border: none;
}
#iqitmegamenu-accordion.cbp-spmenu > li ul ul ul a {
  text-transform: none;
  font-weight: normal;
}
  
#iqitmegamenu-mobile .iqitmegamenu-accordion li a {
  transition: all 0.3s ease;
}
  
.cbp-spmenu div.responsiveInykator {
  margin-right: 10px;
}
   
#cbp-close-mobile {
  padding: 0px;
  text-align: center;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -50px; 
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  border: none !important;
}
#cbp-close-mobile i {
  line-height: 50px;
  font-size: 28px;
}
.cbp-spmenu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 5102;
  visibility: hidden;
  opacity: 0;
  display: none;
}
@media (max-width: 999px) {
  .cbp-spmenu-overlay {
    display: block;
  }
}
  
.cbp-spmenu-overlay-show {
  visibility: visible;
  opacity: 1;
}
.cbp-spmenu-overlay-show #cbp-close-mobile {
  left: 240px; 
  visibility: visible;
  opacity: 1;
}
.cbp-spmenu {
  position: fixed;
  z-index: 5103;
  box-shadow: inset -134px 0px 10px -133px rgba(0, 0, 0, 0.4); 
  overflow-y: auto;
}
  
.cbp-spmenu h3 {
  color: #afdefa;
  font-size: 1.9em;
  padding: 20px;
  margin: 0;
  font-weight: 300;
  background: #0d77b6;
}
  
.cbp-spmenu a {
  display: block;
  color: #fff;
  font-size: 1.1em;
  font-weight: 300;
}
  
.cbp-spmenu > li > ul > li > a:hover {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 23px;
}
  
.cbp-spmenu a:active {
  background: #afdefa;
  color: #47a3da;
}
  
/* Orientation-dependent styles for the content of the menu */
.cbp-spmenu-vertical {
  width: 240px;
  height: 100%;
  top: 0;
}
  
  .cbp-spmenu-vertical a {
    border-bottom: 1px solid #258ecd;
    padding: 1em; }
  
  /* Vertical menu that slides from the left or right */
  .cbp-spmenu-left {
    left: -240px; }
  
  .cbp-spmenu-left.cbp-spmenu-open {
    left: 0px; }
  
  /* Push classes applied to the body */
  .cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0; }
  @media (max-width: 999px){
  .cbp-spmenu-push-toright {
    left: 240px; 
    width: 100%;
    position: fixed;}
    }
  
  .cbp-spmenu-push-toleft {
    left: -240px; }
  

/* Transitions */
.cbp-spmenu,
.cbp-spmenu-push {
    transition: all 0.3s ease;
}

/* Example media queries */
@media screen and (max-width: 55.1875em) {
    .cbp-spmenu-top {
        top: -110px;
    }

    .cbp-spmenu-bottom {
        bottom: -110px;
    }
}

.container-xs-height {
    /*display: table;*/
    padding-left: 0px;
    padding-right: 0px;
}

/*.row-xs-height {
    display: table-row;
}*/
.row-xs-height:before {
    display: none;
}
.row-xs-height:after {
    display: none;
}

.col-xs-height {
    display: table-cell;
    float: none;
    vertical-align: top;
}

#iqitmegamenu-horizontal #search_block_top_content {
    width: auto;
    float: right;
    position: relative;
    width: 160px;
    right: 0px;
}
#iqitmegamenu-horizontal #search_block_top {
    position: absolute;
    top: 0px;
    width: 150px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}

@include media-breakpoint-down(lg) {
    #iqitmegamenu-horizontal > .container {
        position: relative;
    }
}

@include media-breakpoint-down(sm) {
    #iqitmegamenu-horizontal #search_block_top_content {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    #iqitmegamenu-horizontal #search_block_top_content {
        position: absolute;
        right: 20px;
        top: 10px;
        bottom: 10px;
        margin-right: 0px !important;
    }
}

@include media-breakpoint-down(sm) {
    #iqitmegamenu-horizontal #search_block_top {
        left: 0px;
    }
}

.cbp-hor-width-1 #iqitmegamenu-horizontal #search_block_top_content {
    margin-right: 15px;
}

@include media-breakpoint-down(sm) {
    #iqitmegamenu-horizontal {
        background: transparent !important;
        border: none !important;
    }
}

#iqitmegamenu-horizontal.cbp-nosticky .container{
    max-width: 100%;
}

.cbp-hor-width-0 #iqitmegamenu-horizontal{
    margin: 0 !important;
    padding: 0 !important;
}

.cbp-hor-width-0 #iqitmegamenu-horizontal.cbp-sticky{
    margin: 0px;
    padding: 0px;
}

body {
    overflow-x: hidden;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}