body#my-account {
    #content {
        .links {
            a {
                padding: 40px 50px;
                text-decoration: none;
                @extend %transition;

                @include media-breakpoint-up(lg) {
                    &:not(:last-child) {
                        border-right: theme-color(primary) solid 2px;
                    }
                }
                @include media-breakpoint-down(md) {
                    &:not(:last-child) {
                        border-bottom: theme-color(primary) solid 2px;
                    }
                }

                &:hover {
                    background: theme-color(light);

                    span, i {
                        opacity: 0.8;
                    }
                }
            }
            
            span {
                display: block;
                font-weight: 600;
                font-size: 1.2rem;
                line-height: 1.1;
                @extend %transition;
            }
            i {
                color: theme-color(secondary);
                font-size: 1.8rem;
                margin-bottom: 10px;
                @extend %transition;
            }
        }
    }
}
body#authentication {
    #content {
        border: none;
        box-shadow: none;
        background: none;

        & > .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }
}