body#category,
body#search,
body#best-sales,
body#new-products,
body#prices-drop {
    @import "category/facets";

    .description-category-bottom {
        @include media-breakpoint-up(md) {
            .content {
                column-count: 2;
                column-gap: $grid-gutter-width;
            }
        }
        a {
            @extend %link-underline;
        }
    }

    #form-display-products {
        .form-group:not(:last-child) {
            padding-right: 15px;
        }
        @include media-breakpoint-down(md) {
            .form-group {
                margin-bottom: 0;
            }
        }
        label {
            text-transform: uppercase;
            font-weight: 500;
            cursor: pointer;
            position: relative;
            @extend %transition;

            &::after {
                content: "";
                width: 0%;
                height: 1px;
                background: theme-color(primary);
                display: block;
                position: absolute;
                bottom: -5px;
                left: 0;
                z-index: 1;
                @extend %transition;
            }

            @include media-breakpoint-down(md) {
                & {
                    transform: translateY(2px);
                }
                i {                   
                    display: block;
                    font-size: 1.2rem;
                }
            }
            @include media-breakpoint-up(md) {
                i {
                    margin-right: 10px;
                }
            }
        }
        input {
            display: none;

            &:not(:checked) + label {
                color: rgba(theme-color(gray), 0.4);
            }
            &:checked + label::after {
                width: 100%;
            }
        }
    }

    #js-product-list {
        overflow: hidden;
        .item {
            @extend %transition;
            margin-bottom: 30px;
        }
        .products.row[data-display="products"] {
            .item:not(.item-push-article) {
                @extend .col-md-3;

                .situation-img {
                    display: none;
                }
            }
            .item-push-article {
                @extend .col-md-6;
            }
        }
        .products.row[data-display="situations"] {
            .item:not(.item-push-article) {
                @extend .col-md-4;

                .product-img {
                    display: none;
                }
            }
            .item-push-article {
                @extend .col-md-8;
            }
        }
    }

    .small-h1 {
      text-transform: none;
      font-size: 0.9em;
    }

    .titleCategory {
      font-size: 2em;
    }

}