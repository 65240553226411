#home-themes {
    @include media-breakpoint-down(sm) {
        padding: 0;

        .container {
            padding: 0;
        }
    }
    .bg-white {
        border: theme-color(primary) solid 1px;
        @extend %shadow;

        @include media-breakpoint-down(sm) {
            border: none;
            box-shadow: none;
        }

        img.h-100 {
            object-fit: cover;
            object-position: top center;
        }
    }
}