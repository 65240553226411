#modal-customize {
    padding: 0 !important;
    border: 0 !important;
    @include media-breakpoint-up(md) {
        overflow: hidden !important;
    }
    z-index: 9999999999;

    .modal-dialog {
        margin: 0;
        max-width: 100%;

        .modal-content {
            border: none !important;

            @include media-breakpoint-up(md) {
                height: 100vh;

                .modal-body {
                    height: 100vh;

                    .col-images,
                    .col-options {
                        height: 100vh;
                    }
                }
            }
        }
    }
    .upload-scene {
        i {
            font-size: 3rem;
        }
        .btn {
            cursor: pointer;
        }
    }

    .btn-cancel {
        cursor: pointer;
        text-transform: uppercase;
        background: linear-gradient(to right, rgba(theme-color(primary), 0.2) 0%, rgba(theme-color(primary), 0.2) 100%);
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: left 85%;
        @extend %transition;
        padding-bottom: 3px;
        display: inline-block;
        font-weight: 600;
    
        &:hover {
            background-size: 0 1px;
            text-decoration: none;
        }
    }

    .col-options {
        box-shadow: rgba(theme-color(gray), 0.2) -3px 0 6px 0px;
        max-width: 500px;
        flex: 0 0 500px;

        @media (max-width: 1150px) {
            max-width: 400px;
            flex: 0 0 400px;
        }
        @media (max-width: 950px) {
            max-width: 350px;
            flex: 0 0 350px;
        }
        @media (max-width: 800px) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .col-body {
            
            @include media-breakpoint-up(md) {
                overflow-x: hidden;
                overflow-y: auto;
                height: calc(100vh - 230px);
            }

            .customize-options {
                .customize-option {
                    &:not(:last-child) {
                        border-bottom: theme-color(tertiary) solid 1px;
                    }

                    .title {
                        vertical-align: middle;

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            
                            &.num {
                                margin-right: 10px;
                                width: 40px;
                                height: 40px;
                                border-radius: 40px;
                                text-align: center;
                                line-height: 40px;
                                background: theme-color(primary);
                                color: theme-color(white);
                                font-weight: 600;
                            }
                            &.text {
                                font-size: 1.4rem;
                            }
                        }
                    }
                    .content {
                        .row {
                            margin-left: -5px;
                            margin-right: -5px;
                        }
                        [class*="col-"] {
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                        .label-radio {
                            cursor: pointer;
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            font-size: 0.9rem;
                            font-weight: 600;
                            line-height: 1.2;
                            color: rgba(theme-color(primary), 0.5);
                            border: theme-color(tertiary) solid 2px;
                            padding: 10px;
                            min-height: 100%;
                            position: relative;

                            .help {
                                position: absolute;
                                top: -7px;
                                right: -7px;
                                z-index: 2;
                                width: 20px;
                                height: 20px;
                                background: theme-color(primary);
                                color: theme-color(white);
                                font-weight: 700;
                                line-height: 20px;
                                text-align: center;
                                border-radius: 30px;
                            }
                        }
                        input[type=radio]:checked + .label-radio {
                            color: theme-color(primary);
                            box-shadow: 0px 0px 0px 1px theme-color(primary);
                            border-color: theme-color(primary);
                        }
                    }
                }
            }
        }

        .col-footer {
            min-height: 200px;

            .title {
                font-weight: 600;
                font-size: 1.2rem;
            }
            .price {
                color: theme-color(quaternary);
                font-weight: 600;
                font-size: 1.2rem;
            }
        }
    }
}