
$theme-colors: (
    primary: #010910,
    secondary: #DFBCB8,
    tertiary : #D7DADD,
    quaternary: #AC8281,
    light: #F6F6F6,
    black: #000,
    white: #fff,
    success: #14ab22,
    gray: #383838,
    graylight: #D7DADD,
    info: #0071df,
    warning: #ea534b,
    danger: #e42626,
    dark: #5b5b5b,
    greentic: #8EC640,
    blue_fr: #2034B7,
    red_fr: #EE1313
);

@function theme-color($name) {
    @return map-get($theme-colors, $name);
}
@function theme-text-color($color) {
    @if (lightness($color) > 60) {
        @return theme-color(primary);
    } @else {
        @return theme-color(white);
    }
};
@each $name, $color in $theme-colors {
    &.bg-#{$name} {
        color: theme-text-color($color);
        background: $color;

        &.full-prev {
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: -100%;
                z-index: 1;
                width: 100%;
                height: 100%;
                background: $color;
            }
        }

        /*a:not(.btn) {
            @extend %transition;
            display: inline-block;
            position: relative;

            &::after {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                content: "";
                display: inline-block;
                width: 0%;
                height: 1px;
                background: theme-text-color($color);
                transition: all ease .3s;
            }
        
            &:hover {
                opacity: 0.6;

                &::after {
                    width: 100%;
                }
            }
        }*/
        .product-miniature a::after {
            display: none;
        }
    }
    &.color-#{$name} {
        color: $color;
    }
}

@each $name, $color in $theme-colors {
    .fill-#{$name} {
        fill: $color;
    }
}


%transition {
    transition: all .25s ease;
}
%shadow {
    box-shadow: rgba(theme-color(gray), 0.3) 0px 3px 6px 0px;
}
%link-underline {
    background: linear-gradient(to right, rgba(theme-color(primary), 0.2) 0%, rgba(theme-color(primary), 0.2) 100%);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: left 85%;
    @extend %transition;
    padding-bottom: 3px;

    &:hover {
        background-size: 0 1px;
        text-decoration: none;
    }
}

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$font-family-base: $font-futura;
$breadcrumb-divider:  url("../img/diviser.svg");